<template>
    <div class="main">
        <div v-if="type === 1">
            <el-progress
                    :percentage="percentage"
                    :format="format"
                    :text-inside="true"
            ></el-progress>
            <div class="spanBox">
        <span class="testSpan" style="color: #fff">Step1-填写注册信息</span
        ><span class="testSpan"
            ><span v-if="percentage >= 50" style="color: #fff"
            >Step2-认证身份信息</span
            ><span v-else style="color: #a39f9f">Step2-认证身份信息</span></span
            ><span class="testSpan"
            ><span v-if="percentage >= 75" style="color: #fff"
            >Step3-完善个人信息</span
            ><span v-else style="color: #a39f9f">Step3-完善个人信息</span></span
            ><span class="testSpan"
            ><span v-if="percentage === 100" style="color: #fff"
            >Step4-注册完成</span
            ><span v-else style="color: #a39f9f">Step4-注册完成</span></span
            >
            </div>
        </div>
        <div v-if="type === 2">
            <el-progress
                    :percentage="percentage"
                    :format="format"
                    :text-inside="true"
            ></el-progress>
            <div class="spanBox">
        <span class="testSpan" style="color: #fff">Step1-认证身份信息</span
        ><span class="testSpan"
            ><span v-if="percentage >= 50" style="color: #fff"
            >Step2-确认个人信息</span
            ><span v-else style="color: #a39f9f">Step2-确认个人信息</span></span
            ><span class="testSpan"
            ><span v-if="percentage >= 75" style="color: #fff"
            >Step3-填写邀请码</span
            ><span v-else style="color: #a39f9f">Step3-填写邀请码</span></span
            ><span class="testSpan"
            ><span v-if="percentage === 100" style="color: #fff"
            >Step4-选择考点城市</span
            ><span v-else style="color: #a39f9f">Step4-选择考点城市</span></span
            >
            </div>
        </div>

        <div v-if="type === 3">
            <el-progress
                    :percentage="percentage"
                    :format="format"
                    :text-inside="true"
            ></el-progress>
            <div class="spanBox">
                <span class="testSpan">
                    <span v-if="percentage >= 50" style="color: #fff">Step1-填写邀请码</span>
                    <span v-else style="color: #a39f9f">Step1-填写邀请码</span>
                </span>
                <span class="testSpan">
                    <span v-if="percentage === 100" style="color: #fff">Step2-选择考点城市</span>
                    <span v-else style="color: #a39f9f">Step2-选择考点城市</span>
                </span>
            </div>
        </div>
        <!-- 注册 -->
        <div v-if="type === 6">
            <el-progress
                    :percentage="percentage"
                    :format="format"
                    :text-inside="true"
            ></el-progress>
            <div class="spanBox">
                <span class="testSpan" style="color: #fff">Step1-填写注册信息</span>
                <span class="testSpan">
          <span v-if="percentage >= 50" style="color: #fff">Step2-认证身份信息</span>
          <span v-else style="color: #a39f9f">Step2-认证身份信息</span>
        </span>
                <span class="testSpan">
          <span v-if="percentage >= 75" style="color: #fff">Step3-完善个人信息</span>
          <span v-else style="color: #a39f9f">Step3-完善个人信息</span></span>
                <span class="testSpan">
          <span v-if="percentage === 100" style="color: #fff">Step4-注册完成</span>
          <span v-else style="color: #a39f9f">Step4-注册完成</span>
        </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "commStep",
        props: {
            active: Number,
            step: String,
            percentage: Number,
            type: Number
        },
        methods: {
            format(percentage) {
                return percentage + "%";
            },
        },
    };
</script>

<style scoped lang="scss">
    .main {
        width: 100%;

        .spanBox {
            position: relative;
            top: -18px;
            display: flex;

            .testSpan {
                display: block;
                flex: 1;
                text-align: center;
                font-size: 10px;
            }
        }
    }

    ::v-deep .el-progress-bar__outer {
        width: 100%;
        padding: 12px 0;
    }

    ::v-deep .spanBox {
        position: relative;
        top: -20px !important;
    }

    ::v-deep .el-progress__text {
        width: 0;
        display: none;
        color: #a39f9f;
    }

    ::v-deep .el-progress-bar__innerText {
        display: none;
    }

    ::v-deep .el-progress-bar__inner {
        background: #590f77;
    }
</style>
