<template>
  <el-dialog
    title="确认信息"
    :visible.sync="dialogVisible"
    width="520px"
    top="0"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <div class="info_2">本次考试的个人信息一旦确认，无法修改，是否确认？</div>

    <span slot="footer" class="dialog-footer">
      <el-button class="cancel" @click="cancelBtn()">取 消</el-button>
      <el-button class="confirm" @click="confirm()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {seat} from "r/index/signUp";

export default {
  name: "Reserve",
  props: {
    reserveData:Object,
    reserveDialogFu: Boolean,
    examuuid: String,
  },
  data() {
    return {
      dialogVisible: false,
      reserveDatas:this.reserveData,
    };
  },
  methods: {
   async confirm() {
     this.dialogVisible = false;
      this.$emit("closeReserveDialog", this.dialogVisible, true);
    },
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.$emit("closeReserveDialog", this.dialogVisible, false);
    },
  },
  watch: {
    reserveDialogFu(val) {
      this.dialogVisible = val;
    },
    reserveData(val){
      this.reserveDatas = val;
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/dialog";
</style>
