<template>
  <div id="main">
    <el-main>
      <bread-crumb :type="2" :step="2"></bread-crumb>
      <div class="formBox">
        <comm-step :type="2" :percentage="50"></comm-step>
        <self-info
          :selfInfos="selfInfo"
          @getValues="getValue"
          ref="selfInfos"
        ></self-info>
        <self-info-sure
          :reserveDialogFu="reserveDialogFu"
          @closeReserveDialog="closeReserveDialog"
        >
        </self-info-sure>
        <div style="width: 100%; padding-top: 20px; text-align: center">
          <el-button :disabled="isDisable" @click="toInvitation">下一步</el-button>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import commStep from "c/commStep";
import Header from "c/index/Header";
import breadCrumb from "c/breadcrumb";
import selfInfoSure from "c/index/signUp/selfInfoSure";
import { getAccount, setAccount } from "r/index/signUp";
import selfInfo from "c/selfInfo";
export default {
  data() {
    return {
      reserveDialogFu: false,
      examUuid: "", //当前考试uuid

      //个人信息
      selfInfo: {
        //个人信息
        name: "", //学生姓名
        phone: "", //手机号
        sex: 0, //性别 0男  1女
        email: "", //邮箱

        jiguan_code: undefined, //籍贯 籍贯code 【省份id+城市id】
        shengyuandi_code: undefined, //生源code 【省份id+城市id】
        birthday: "", //出生日期，类似20020624
        nation: "", //民族
        political_status: "", //政治面貌
        start_school_date: "", //入学年份
        category: "", //科类[文理科 1 文科 2 理科 3 选科]
        gk_selectsubject: "", //选科科目【格式：3001#3002#3008】
        foreign_language: "", //语种
        address_code: "", //家庭住址省市code【省份id+城市id】
        address: "", //家庭住址
        familyinfo: [
          //家庭信息
          {
            career: "",
            educationdegree: "",
            job: "",
            name: "",
            phone: "",
            relation: "",
            workplace: "",
          },
          {
            career: "",
            educationdegree: "",
            job: "",
            name: "",
            phone: "",
            relation: "",
            workplace: "",
          },
        ],
        schoolprovince: "", //学校省份code（id）通过【获取所有省份列表】获得
        schoolcity: "", //学校城市code（id）通过【获取所有城市列表】获得
        school: "", //学校code（id）通过【获取所有学校列表】获得
        schoolinfo: {
          //学校信息
          contactpeople: "",
          contactphone: "",
          address: "",
          jdcphone: "",
          postcode: "",
          province: "",
          city: "",
          name: "",
        },
        scoreinfo: [
          //成绩信息
          {
            grade: "高一上(期末)",
            gradeIndex: 0,
            scoreItems: [
              {
                subjectIndex: 0,
                myscore: "",
                subjectName: "语文",
                totalscore: "",
              },
              {
                subjectIndex: 1,
                myscore: "",
                subjectName: "数学",
                totalscore: "",
              },
              {
                subjectIndex: 2,
                myscore: "",
                subjectName: "英语",
                totalscore: "",
              },
              {
                subjectIndex: 3,
                myscore: "",
                subjectName: "物理",
                totalscore: "",
              },
              {
                subjectIndex: 4,
                myscore: "",
                subjectName: "化学",
                totalscore: "",
              },
              {
                subjectIndex: 5,
                myscore: "",
                subjectName: "生物",
                totalscore: "",
              },
              {
                subjectIndex: 6,
                myscore: "",
                subjectName: "政治",
                totalscore: "",
              },
              {
                subjectIndex: 7,
                myscore: "",
                subjectName: "历史",
                totalscore: "",
              },
              {
                subjectIndex: 8,
                myscore: "",
                subjectName: "地理",
                totalscore: "",
              },
              {
                subjectIndex: 9,
                myscore: "",
                subjectName: "文理(综合)",
                totalscore: "",
              },
              {
                subjectIndex: 10,
                myscore: "",
                subjectName: "年级排名",
                totalscore: "",
              },
            ],
          },
          {
            grade: "高一下(期末)",
            gradeIndex: 1,
            scoreItems: [
              {
                subjectIndex: 0,
                myscore: "",
                subjectName: "语文",
                totalscore: "",
              },
              {
                subjectIndex: 1,
                myscore: "",
                subjectName: "数学",
                totalscore: "",
              },
              {
                subjectIndex: 2,
                myscore: "",
                subjectName: "英语",
                totalscore: "",
              },
              {
                subjectIndex: 3,
                myscore: "",
                subjectName: "物理",
                totalscore: "",
              },
              {
                subjectIndex: 4,
                myscore: "",
                subjectName: "化学",
                totalscore: "",
              },
              {
                subjectIndex: 5,
                myscore: "",
                subjectName: "生物",
                totalscore: "",
              },
              {
                subjectIndex: 6,
                myscore: "",
                subjectName: "政治",
                totalscore: "",
              },
              {
                subjectIndex: 7,
                myscore: "",
                subjectName: "历史",
                totalscore: "",
              },
              {
                subjectIndex: 8,
                myscore: "",
                subjectName: "地理",
                totalscore: "",
              },
              {
                subjectIndex: 9,
                myscore: "",
                subjectName: "文理(综合)",
                totalscore: "",
              },
              {
                subjectIndex: 10,
                myscore: "",
                subjectName: "年级排名",
                totalscore: "",
              },
            ],
          },
          {
            grade: "高二上(期末)",
            gradeIndex: 2,
            scoreItems: [
              {
                subjectIndex: 0,
                myscore: "",
                subjectName: "语文",
                totalscore: "",
              },
              {
                subjectIndex: 1,
                myscore: "",
                subjectName: "数学",
                totalscore: "",
              },
              {
                subjectIndex: 2,
                myscore: "",
                subjectName: "英语",
                totalscore: "",
              },
              {
                subjectIndex: 3,
                myscore: "",
                subjectName: "物理",
                totalscore: "",
              },
              {
                subjectIndex: 4,
                myscore: "",
                subjectName: "化学",
                totalscore: "",
              },
              {
                subjectIndex: 5,
                myscore: "",
                subjectName: "生物",
                totalscore: "",
              },
              {
                subjectIndex: 6,
                myscore: "",
                subjectName: "政治",
                totalscore: "",
              },
              {
                subjectIndex: 7,
                myscore: "",
                subjectName: "历史",
                totalscore: "",
              },
              {
                subjectIndex: 8,
                myscore: "",
                subjectName: "地理",
                totalscore: "",
              },
              {
                subjectIndex: 9,
                myscore: "",
                subjectName: "文理(综合)",
                totalscore: "",
              },
              {
                subjectIndex: 10,
                myscore: "",
                subjectName: "年级排名",
                totalscore: "",
              },
            ],
          },
          {
            grade: "高二下(期末)",
            gradeIndex: 3,
            scoreItems: [
              {
                subjectIndex: 0,
                myscore: "",
                subjectName: "语文",
                totalscore: "",
              },
              {
                subjectIndex: 1,
                myscore: "",
                subjectName: "数学",
                totalscore: "",
              },
              {
                subjectIndex: 2,
                myscore: "",
                subjectName: "英语",
                totalscore: "",
              },
              {
                subjectIndex: 3,
                myscore: "",
                subjectName: "物理",
                totalscore: "",
              },
              {
                subjectIndex: 4,
                myscore: "",
                subjectName: "化学",
                totalscore: "",
              },
              {
                subjectIndex: 5,
                myscore: "",
                subjectName: "生物",
                totalscore: "",
              },
              {
                subjectIndex: 6,
                myscore: "",
                subjectName: "政治",
                totalscore: "",
              },
              {
                subjectIndex: 7,
                myscore: "",
                subjectName: "历史",
                totalscore: "",
              },
              {
                subjectIndex: 8,
                myscore: "",
                subjectName: "地理",
                totalscore: "",
              },
              {
                subjectIndex: 9,
                myscore: "",
                subjectName: "文理(综合)",
                totalscore: "",
              },
              {
                subjectIndex: 10,
                myscore: "",
                subjectName: "年级排名",
                totalscore: "",
              },
            ],
          },
          {
            grade: "高三上(期末)",
            gradeIndex: 4,
            scoreItems: [
              {
                subjectIndex: 0,
                myscore: "",
                subjectName: "语文",
                totalscore: "",
              },
              {
                subjectIndex: 1,
                myscore: "",
                subjectName: "数学",
                totalscore: "",
              },
              {
                subjectIndex: 2,
                myscore: "",
                subjectName: "英语",
                totalscore: "",
              },
              {
                subjectIndex: 3,
                myscore: "",
                subjectName: "物理",
                totalscore: "",
              },
              {
                subjectIndex: 4,
                myscore: "",
                subjectName: "化学",
                totalscore: "",
              },
              {
                subjectIndex: 5,
                myscore: "",
                subjectName: "生物",
                totalscore: "",
              },
              {
                subjectIndex: 6,
                myscore: "",
                subjectName: "政治",
                totalscore: "",
              },
              {
                subjectIndex: 7,
                myscore: "",
                subjectName: "历史",
                totalscore: "",
              },
              {
                subjectIndex: 8,
                myscore: "",
                subjectName: "地理",
                totalscore: "",
              },
              {
                subjectIndex: 9,
                myscore: "",
                subjectName: "文理(综合)",
                totalscore: "",
              },
              {
                subjectIndex: 10,
                myscore: "",
                subjectName: "年级排名",
                totalscore: "",
              },
            ],
          },
          {
            grade: "最后大考",
            gradeIndex: 5,
            scoreItems: [
              {
                subjectIndex: 0,
                myscore: "",
                subjectName: "语文",
                totalscore: "",
              },
              {
                subjectIndex: 1,
                myscore: "",
                subjectName: "数学",
                totalscore: "",
              },
              {
                subjectIndex: 2,
                myscore: "",
                subjectName: "英语",
                totalscore: "",
              },
              {
                subjectIndex: 3,
                myscore: "",
                subjectName: "物理",
                totalscore: "",
              },
              {
                subjectIndex: 4,
                myscore: "",
                subjectName: "化学",
                totalscore: "",
              },
              {
                subjectIndex: 5,
                myscore: "",
                subjectName: "生物",
                totalscore: "",
              },
              {
                subjectIndex: 6,
                myscore: "",
                subjectName: "政治",
                totalscore: "",
              },
              {
                subjectIndex: 7,
                myscore: "",
                subjectName: "历史",
                totalscore: "",
              },
              {
                subjectIndex: 8,
                myscore: "",
                subjectName: "地理",
                totalscore: "",
              },
              {
                subjectIndex: 9,
                myscore: "",
                subjectName: "文理(综合)",
                totalscore: "",
              },
              {
                subjectIndex: 10,
                myscore: "",
                subjectName: "年级排名",
                totalscore: "",
              },
            ],
          },
        ],
        wishinfo: [
          //国内志愿
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zy3id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
            zy3name: "",
          },
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zy3id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
            zy3name: "",
          },
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zy3id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
            zy3name: "",
          },
        ],
        coopwishinfo: [
          //国外志愿
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
          },
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
          },
        ],
        qjwishinfo: [
          //强基计划志愿
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zy3id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
            zy3name: "",
          },
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zy3id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
            zy3name: "",
          },
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zy3id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
            zy3name: "",
          },
        ],
      },
      isDisable: false//禁用 false-不禁用，true-禁用
    };
  },
  components: {
    breadCrumb,
    Header,
    commStep,
    selfInfo,
    selfInfoSure
  },
  created() {
    if(!window.localStorage.getItem('exam_uuid') || window.localStorage.getItem('exam_uuid')=='' || window.localStorage.getItem('exam_uuid')=='null'){
      return this.$router.replace({name:'examlist'})
    }
    this.examUuid = window.localStorage.getItem("exam_uuid");
    this.getAccountList();
  },
  methods: {
    //获取个人信息
    getAccountList() {
      if (this.examUuid && this.examUuid !== "") {
        getAccount({ exam_uuid: this.examUuid })
          .then((res) => {
            console.log(res, "res");
            if (res.data.code === 200) {
              this.selfInfo = res.data.info;
              if (!res.data.info.familyinfo) {
                this.selfInfo.familyinfo = [
                  //家庭信息
                  {
                    career: "",
                    educationdegree: "",
                    job: "",
                    name: "",
                    phone: "",
                    relation: "",
                    workplace: "",
                  },
                  {
                    career: "",
                    educationdegree: "",
                    job: "",
                    name: "",
                    phone: "",
                    relation: "",
                    workplace: "",
                  },
                ];
              }
              if (!res.data.info.schoolinfo) {
                this.selfInfo.schoolinfo = {
                  //学校信息
                  contactpeople: "",
                  contactphone: "",
                  address: "",
                  jdcphone: "",
                  postcode: "",
                  province: "",
                  city: "",
                  name: "",
                };
              }
              if (!res.data.info.scoreinfo || res.data.info.scoreinfo.length<=0) {
                this.selfInfo.scoreinfo = [
                  //成绩信息
                  {
                    grade: "高一上(期末)",
                    gradeIndex: 0,
                    scoreItems: [
                      {
                        subjectIndex: 0,
                        myscore: "",
                        subjectName: "语文",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 1,
                        myscore: "",
                        subjectName: "数学",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 2,
                        myscore: "",
                        subjectName: "英语",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 3,
                        myscore: "",
                        subjectName: "物理",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 4,
                        myscore: "",
                        subjectName: "化学",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 5,
                        myscore: "",
                        subjectName: "生物",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 6,
                        myscore: "",
                        subjectName: "政治",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 7,
                        myscore: "",
                        subjectName: "历史",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 8,
                        myscore: "",
                        subjectName: "地理",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 9,
                        myscore: "",
                        subjectName: "文理(综合)",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 10,
                        myscore: "",
                        subjectName: "年级排名",
                        totalscore: "",
                      },
                    ],
                  },
                  {
                    grade: "高一下(期末)",
                    gradeIndex: 1,
                    scoreItems: [
                      {
                        subjectIndex: 0,
                        myscore: "",
                        subjectName: "语文",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 1,
                        myscore: "",
                        subjectName: "数学",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 2,
                        myscore: "",
                        subjectName: "英语",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 3,
                        myscore: "",
                        subjectName: "物理",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 4,
                        myscore: "",
                        subjectName: "化学",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 5,
                        myscore: "",
                        subjectName: "生物",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 6,
                        myscore: "",
                        subjectName: "政治",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 7,
                        myscore: "",
                        subjectName: "历史",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 8,
                        myscore: "",
                        subjectName: "地理",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 9,
                        myscore: "",
                        subjectName: "文理(综合)",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 10,
                        myscore: "",
                        subjectName: "年级排名",
                        totalscore: "",
                      },
                    ],
                  },
                  {
                    grade: "高二上(期末)",
                    gradeIndex: 2,
                    scoreItems: [
                      {
                        subjectIndex: 0,
                        myscore: "",
                        subjectName: "语文",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 1,
                        myscore: "",
                        subjectName: "数学",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 2,
                        myscore: "",
                        subjectName: "英语",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 3,
                        myscore: "",
                        subjectName: "物理",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 4,
                        myscore: "",
                        subjectName: "化学",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 5,
                        myscore: "",
                        subjectName: "生物",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 6,
                        myscore: "",
                        subjectName: "政治",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 7,
                        myscore: "",
                        subjectName: "历史",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 8,
                        myscore: "",
                        subjectName: "地理",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 9,
                        myscore: "",
                        subjectName: "文理(综合)",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 10,
                        myscore: "",
                        subjectName: "年级排名",
                        totalscore: "",
                      },
                    ],
                  },
                  {
                    grade: "高二下(期末)",
                    gradeIndex: 3,
                    scoreItems: [
                      {
                        subjectIndex: 0,
                        myscore: "",
                        subjectName: "语文",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 1,
                        myscore: "",
                        subjectName: "数学",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 2,
                        myscore: "",
                        subjectName: "英语",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 3,
                        myscore: "",
                        subjectName: "物理",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 4,
                        myscore: "",
                        subjectName: "化学",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 5,
                        myscore: "",
                        subjectName: "生物",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 6,
                        myscore: "",
                        subjectName: "政治",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 7,
                        myscore: "",
                        subjectName: "历史",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 8,
                        myscore: "",
                        subjectName: "地理",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 9,
                        myscore: "",
                        subjectName: "文理(综合)",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 10,
                        myscore: "",
                        subjectName: "年级排名",
                        totalscore: "",
                      },
                    ],
                  },
                  {
                    grade: "高三上(期末)",
                    gradeIndex: 4,
                    scoreItems: [
                      {
                        subjectIndex: 0,
                        myscore: "",
                        subjectName: "语文",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 1,
                        myscore: "",
                        subjectName: "数学",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 2,
                        myscore: "",
                        subjectName: "英语",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 3,
                        myscore: "",
                        subjectName: "物理",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 4,
                        myscore: "",
                        subjectName: "化学",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 5,
                        myscore: "",
                        subjectName: "生物",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 6,
                        myscore: "",
                        subjectName: "政治",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 7,
                        myscore: "",
                        subjectName: "历史",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 8,
                        myscore: "",
                        subjectName: "地理",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 9,
                        myscore: "",
                        subjectName: "文理(综合)",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 10,
                        myscore: "",
                        subjectName: "年级排名",
                        totalscore: "",
                      },
                    ],
                  },
                  {
                    grade: "最后大考",
                    gradeIndex: 5,
                    scoreItems: [
                      {
                        subjectIndex: 0,
                        myscore: "",
                        subjectName: "语文",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 1,
                        myscore: "",
                        subjectName: "数学",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 2,
                        myscore: "",
                        subjectName: "英语",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 3,
                        myscore: "",
                        subjectName: "物理",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 4,
                        myscore: "",
                        subjectName: "化学",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 5,
                        myscore: "",
                        subjectName: "生物",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 6,
                        myscore: "",
                        subjectName: "政治",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 7,
                        myscore: "",
                        subjectName: "历史",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 8,
                        myscore: "",
                        subjectName: "地理",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 9,
                        myscore: "",
                        subjectName: "文理(综合)",
                        totalscore: "",
                      },
                      {
                        subjectIndex: 10,
                        myscore: "",
                        subjectName: "年级排名",
                        totalscore: "",
                      },
                    ],
                  },
                ];
              }
              if (!res.data.info.wishinfo) {
                this.selfInfo.wishinfo = [
                  //国内志愿
                  {
                    zyunivid: "0",
                    zy1id: "0",
                    zy2id: "0",
                    zy3id: "0",
                    zyunivname: "",
                    zy1name: "",
                    zy2name: "",
                    zy3name: "",
                  },
                  {
                    zyunivid: "0",
                    zy1id: "0",
                    zy2id: "0",
                    zy3id: "0",
                    zyunivname: "",
                    zy1name: "",
                    zy2name: "",
                    zy3name: "",
                  },
                  {
                    zyunivid: "0",
                    zy1id: "0",
                    zy2id: "0",
                    zy3id: "0",
                    zyunivname: "",
                    zy1name: "",
                    zy2name: "",
                    zy3name: "",
                  },
                ];
              }
              if (!res.data.info.coopwishinfo) {
                this.selfInfo.coopwishinfo = [
                  //国外志愿
                  {
                    zyunivid: "0",
                    zy1id: "0",
                    zy2id: "0",
                    zyunivname: "",
                    zy1name: "",
                    zy2name: "",
                  },
                  {
                    zyunivid: "0",
                    zy1id: "0",
                    zy2id: "0",
                    zyunivname: "",
                    zy1name: "",
                    zy2name: "",
                  },
                ];
              }
              if (!res.data.info.qjwishinfo) {
                this.selfInfo.qjwishinfo = [
                  //强基计划志愿
                  {
                    zyunivid: "0",
                    zy1id: "0",
                    zy2id: "0",
                    zy3id: "0",
                    zyunivname: "",
                    zy1name: "",
                    zy2name: "",
                    zy3name: "",
                  },
                  {
                    zyunivid: "0",
                    zy1id: "0",
                    zy2id: "0",
                    zy3id: "0",
                    zyunivname: "",
                    zy1name: "",
                    zy2name: "",
                    zy3name: "",
                  },
                  {
                    zyunivid: "0",
                    zy1id: "0",
                    zy2id: "0",
                    zy3id: "0",
                    zyunivname: "",
                    zy1name: "",
                    zy2name: "",
                    zy3name: "",
                  },
                ];
              }
              if (!res.data.info.start_school_date) {
                this.selfInfo.start_school_date = "";
              }
              if (!res.data.info.schoolprovince) {
                this.selfInfo.schoolprovince = "";
              }
              if (!res.data.info.schoolcity) {
                this.selfInfo.schoolcity = "";
              }
              if (!res.data.info.school) {
                this.selfInfo.school = "";
              }
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.error("获取uuid失败，请检查网络并刷新页面重试");
      }
    },
    //点击下一步
    toInvitation() {
      this.reserveDialogFu = true;
      // this.$refs.selfInfos.getFormData();
    },
    //关闭弹框
    closeReserveDialog(val, type) {
      this.reserveDialogFu = val;
      if(type){
        this.$refs.selfInfos.getFormData();
      }
    },
    getValue(val) {
      console.log(val);
      if(this.isDisable){
        return;
      }
      this.isDisable = true;
      setAccount(val)
        .then((res) => {
          console.log(res, "res");
          if (res.data.code === 200) {
            this.isDisable = false;
            console.log("保存成功");
            this.$router.replace({ name: "invatation" });
          } else {
            this.isDisable = false;
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          this.isDisable = false;
          this.$message.error(err);
        });
      //
      console.log(val, "子组件传的值");
    },
  },
};
</script>

<style scoped lang="scss">
#main {
  width: 100%;
  min-height: 80%;
  overflow: auto;
  .el-main {
    max-width: 1300px;
    min-width: 1124px;
    margin: 6px auto;
    padding: 0 50px;

    .formBox {
      height: auto;
      background: #fff;
      margin: auto;
      padding: 50px;
      overflow: scroll;

      // .form-room-date {
      //   display: flex;
      // }
    }
  }
}
::v-deep .el-button {
  background: #563279;
  color: #fff;
}
</style>
