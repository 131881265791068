<template>
  <!--头部-->
  <div class="header-box">
    <div class="header-content">
      <img
        src="../../assets/images/logo.svg"
        alt=""
        class="img_logo"
        @click="$router.push('/examList/examlist')"
      />
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          <img src="../../assets/images/default.svg" class="img_user" />
          <span class="phone">{{ name }}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="0">查看个人信息</el-dropdown-item>
          <el-dropdown-item command="1">我的考试</el-dropdown-item>
          <el-dropdown-item command="2">修改密码</el-dropdown-item>
          <el-dropdown-item command="3">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { logout } from "../../request/index/login";

export default {
  name: "Header",
  data() {
    return {
      name: window.localStorage.getItem("name"),
    };
  },
  methods: {
    //点击显示下拉菜单
    showMenu() {},
    handleCommand(command) {
      if (command == 0) {
        this.$router.push({ name: "personalinfo" });
      } else if (command == 1) {
        this.$router.push({ name: "OrderList" });
      } else if (command == 2) {
        this.changePass();
      } else if (command == 3) {
        this.logout();
      }
    },
    // 修改密码
    changePass() {
      // 使用编程式导航跳转到修改密码页面
      this.$router.push("/foundPassword/changePassword");
    },
    // 退出登录方法
    async logout() {
      const confirmResult = await this.$confirm(`确认退出登录？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      }).catch((err) => err);
      if (confirmResult !== "confirm")
        return this.$message.info("您取消了退出");

      await logout({});
      // if (res.code !== 200) return this.$message.error(res.message);
      // 清除本地的 token
      let studentId = window.localStorage.getItem("student_id");
      for (let k in window.localStorage) {
        if (k != "exam_notice_"+studentId && k!="agree-login") {
          window.localStorage.removeItem(k);
        }
      }
      this.$store.state.token = "";
      // 使用编程式导航跳转到登录页面
      await this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/dialog";
.header-box {
  width: 100%;
  height: 66px;
  line-height: 66px;
  background-color: #ffffff;
  box-shadow: 0px 2px 6px 0px #d5d5d5;

  .header-content {
    max-width: 1300px;
    height: 66px;
    line-height: 66px;
    margin: 0 auto;
    padding: 0 50px;

    h2 {
      float: left;
      font-size: 32px;
      font-family: TimesNewRomanPS-BoldMT, TimesNewRomanPS;
      font-weight: normal;
      color: #563279;
    }
    .img_logo {
      float: left;
      // width: 148px;
      height: 45px;
      margin: 10px 0 0 0;
      &:hover {
        cursor: pointer;
      }
    }
    .el-dropdown {
      float: right;
      color: #606266;
      font-size: 14px;
      display: block;
      height: 66px;
      line-height: 66px;
      &:hover {
        cursor: pointer;
      }
      .img_user {
        width: 34px;
        height: 34px;
        background-color: #d8d8d8;
        border-radius: 50%;
        float: left;
        margin: 15px 0 0 0;
      }
      .phone {
        margin: 0 10px 0 12px;
      }
    }
  }
}
::v-deep .el-dropdown-menu__item {
  min-width: 124px !important;
}
::v-deep .el-dropdown-item {
  display: inline-block;
}

::v-deep .el-dropdown-menu__item:hover {
  color: #ffffff;
  background-color: #563279;
}

::v-deep .el-message-box {
  width: 1000px !important;
  .el-message-box__header {
    .el-message-box__title {
      color: red !important;
    }
  }
  .el-button--primary {
    color: #fff;
    background-color: #563279;
    border-color: #563279;
  }
}
</style>
